import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import Loading from "./assets/loading.png";
import { v1 as uuidv1 } from "uuid";
import { setTranslates } from "./components/helpers";

import ApiService from "./api/axios";
const OrderHistory = React.lazy(() => import("./pages/Account/OrderHistory"));
const ProfileDetail = React.lazy(() => import("./pages/Account/ProfileDetail"));
const ChangePassword = React.lazy(() =>
  import("./pages/Account/ChangePassword")
);
const ProductDetail = React.lazy(() => import("./pages/ProductDetail"));
const Header = React.lazy(() => import("./components/Header/HeaderParent"));
const Footer = React.lazy(() => import("./components/Footer"));
const Home = React.lazy(() => import("./pages/Home"));
const Services = React.lazy(() => import("./pages/Services"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const BlogDetail = React.lazy(() => import("./pages/BlogDetail"));
const ServiceDetail = React.lazy(() => import("./pages/ServiceDetail"));
const Products = React.lazy(() => import("./pages/Products"));
const SubProducts = React.lazy(() => import("./pages/SubProducts"));
const Profile = React.lazy(() => import("./pages/Account/Profile"));
const Login = React.lazy(() => import("./pages/Authentification/Login"));
const Register = React.lazy(() => import("./pages/Authentification/Register"));
const Forgot = React.lazy(() => import("./pages/Authentification/Forgot"));
const About = React.lazy(() => import("./pages/About"));
const Stores = React.lazy(() => import("./pages/Stores"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Fav = React.lazy(() => import("./pages/Account/Fav"));
const Faq = React.lazy(() => import("./pages/Support/Faq"));
const Privacy = React.lazy(() => import("./pages/Support/Privacy"));
const Terms = React.lazy(() => import("./pages/Support/Terms"));
const Payments = React.lazy(() => import("./pages/Support/Payments"));

const Cart = React.lazy(() => import("./pages/Basket/Cart"));
const CartAddress = React.lazy(() => import("./pages/Basket/CartAddress"));
const Payment = React.lazy(() => import("./pages/Basket/Payment"));
const Error = React.lazy(() => import("./pages/Error"));
const CartSuccess = React.lazy(() => import("./pages/Basket/CartSuccess"));
const CartError = React.lazy(() => import("./pages/Basket/CartError"));

const App = ({ hideHeaderPaths = ["/error"] }) => {
  const uuid = uuidv1();
  const counter = useSelector((state) => state);

  const { pathname } = useLocation();
  const [setting, setSetting] = useState("");
  const [menuCategories, setMenuCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.get("/layouts").then((resp) => {
      setSetting(resp.data.setting);
      setMenuCategories(resp.data.categories);
    });
    setTranslates();

    if (localStorage.token) {
      localStorage.removeItem("guest_uuid", uuid);
    } else {
      localStorage.setItem("guest_uuid", uuid);
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const requireLogin = () => {
    let logged_in = false;
    if (localStorage.token) {
      logged_in = true;
    }
    if (!logged_in) {
      navigate("/login");
    }
  };

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="loading">
            <img src={Loading} alt="loading" />
          </div>
        }
      >
        {!hideHeaderPaths.includes(pathname) && (
          <Header allCategory={menuCategories} setting={setting} />
        )}

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/service/:slug" element={<ServiceDetail />} />
          <Route exact path="/blog" element={<Blogs />} />
          <Route exact path="/blog/:slug" element={<BlogDetail />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/sub-products" element={<SubProducts />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route
            onenter={() => requireLogin()}
            exact
            path="/profile"
            element={<Profile />}
          />
          <Route
            onenter={() => requireLogin()}
            exact
            path="/fav"
            element={<Fav />}
          />
          <Route
            onenter={() => requireLogin()}
            exact
            path="/profile-detail"
            element={<ProfileDetail />}
          />
          <Route
            onenter={() => requireLogin()}
            exact
            path="/change-password"
            element={<ChangePassword />}
          />
          <Route
            onenter={() => requireLogin()}
            exact
            path="/order-history"
            element={<OrderHistory />}
          />
          <Route exact path="/product/:slug" element={<ProductDetail />} />
          <Route exact path="/stores" element={<Stores />} />
          <Route exact path="/stores/:slug" element={<Stores />} />
          <Route exact path="/contact" element={<Contact />} />

          <Route exact path="/support/faq" element={<Faq />} />
          <Route exact path="/support/privacy" element={<Privacy />} />
          <Route exact path="/support/terms" element={<Terms />} />
          <Route exact path="/support/payment" element={<Payments />} />

          <Route exact path="/cart" element={<Cart />} />
          {/* <Route exact path="/cart-address" element={<CartAddress />} />
          <Route exact path="/payment" element={<Payment />} /> */}
          <Route exact path="/success_payment" element={<CartSuccess />} />
          <Route exact path="/error0_payment" element={<CartError />} />

          <Route exact path="*" element={<Error />} />
        </Routes>
        {!hideHeaderPaths.includes(pathname) && (
          <Footer allCategory={menuCategories} setting={setting} />
        )}
      </Suspense>
    </div>
  );
};

export default App;
