import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import { addToCartItem, deleteCartItem } from "../helpers";
import cart, { addToCart, deleteCart } from "../reducers/cartSlice";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: addToCart,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const cart = listenerApi.getState().cart;
    // await addToCartItem(cart.products);
  },
});

listenerMiddleware.startListening({
  actionCreator: deleteCart,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    await deleteCartItem(action.payload);
  },
});

export const store = configureStore({
  reducer: {
    cart,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});
