import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
// import Store from "./components/reducers/store.jsx";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./components/reducers/stores.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter
    onUpdate={() => {
      console.log("updated");
      window.scrollTo(0, 0);
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
    
  </BrowserRouter>
);

reportWebVitals();
